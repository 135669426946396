import { css, cx, keyframes } from '@emotion/css';
import styled from '@emotion/styled';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // eslint-disable-line import/no-extraneous-dependencies
import type { ReactNode } from 'react';
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { graphql, useFragment } from 'react-relay';

import { LightningBoltSvg } from 'ms-components/gamification/LightningBoltSvg';
import { useSnowplow } from 'ms-helpers/Snowplow/useSnowplow';
import useFeatureFlagsV2 from 'ms-helpers/useFeatureFlagsV2';
import { HeadingXXS } from 'ms-pages/Lantern/primitives/Typography';
import Milo from 'ms-pages/StudentDashboard/SunflowerStudentDashboard/Milo/Milo';
import { fontFamily } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { Logger } from 'ms-utils/app-logging';
import { useLocalSyncedState } from 'ms-utils/hooks/useSessionSyncedState';
import { useTypedRive } from 'ms-utils/rive/useTypedRive';
import { unsafeKeys } from 'ms-utils/typescript-utils';

import { BasketballSvg } from './BasketballSvg';
import { CactusSvg } from './CactusSvg';
import { EgyptianCatSvg } from './EgyptianCatSvg';
import { ElephantSvg } from './ElephantSvg';
import { FirecrackerSvg } from './FirecrackerSvg';
import { FootballSvg } from './FootballSvg';
import { FoxSvg } from './FoxSvg';
import { LockSvg } from './LockSvg';
import type { AvailableMonth as MonthlyChallengeAvailableMonth } from './MonthlyExpedition/config';
import type { CustomMiloMonthlyExpeditionItemsState } from './MonthlyExpedition/util';
import { OctopusSvg } from './OctopusSvg';
import { PandaSvg } from './PandaSvg';
import { RobotSvg } from './RobotSvg';
import { SandcastleSvg } from './SandcastleSvg';
import type { CustomMilo_student$key } from './__generated__/CustomMilo_student.graphql';
import customMiloRiv from './custom_milo.riv';
import { artboard, stateMachine } from './types';
import type { Animation } from './types';

const HIGHEST_WEEKLY_POINT_TOTAL_KEY = 'HIGHEST_WEEKLY_POINT_TOTAL';

type AnimationUnlockableWithChallenges = Extract<
  Animation,
  'FOX' | 'CAT' | 'PANDA'
>;

type AnimationUnlockableWithPoints = Exclude<
  Animation,
  AnimationUnlockableWithChallenges
>;

export function CustomMilo({
  studentKey,
  customMiloMonthlyExpeditionItemsState,
}: {
  studentKey: CustomMilo_student$key;
  customMiloMonthlyExpeditionItemsState: CustomMiloMonthlyExpeditionItemsState;
}) {
  const { gamificationEnableMonthlyExpedition } = useFeatureFlagsV2();
  const studentData = useFragment(
    graphql`
      fragment CustomMilo_student on Student
      @argumentDefinitions(
        fetchLeaderboard: { type: "Boolean!" }
        numberOfClasses: { type: "Int!" }
        classId: { type: "ID" }
      ) {
        id
        leaderboard @include(if: $fetchLeaderboard) {
          currentWeek(classId: $classId) {
            points
            studentId
          }
        }
      }
    `,
    studentKey,
  );
  const { id = null, leaderboard = null } = studentData ?? {};
  const currWeek = leaderboard?.currentWeek ?? null;
  const currPoints = useMemo(
    () => currWeek?.find(student => student.studentId === id)?.points ?? 0,
    [currWeek, id],
  );

  const { RiveComponent: CustomMiloAnims, rive } = useTypedRive({
    src: customMiloRiv,
    artboard,
    stateMachine,
    autoplay: true,
  });
  const [animation, setAnimation] = useState<Animation>('ENTRY');
  const [activePickerItem, setActivePickerItem] =
    useLocalSyncedState<Animation>({
      dbKey: 'CUSTOM_MILO__ACTIVE',
      fallbackValue: 'ENTRY',
    });
  const [newlyUnlockedItemCount, setNewlyUnlockedItemCount] =
    useLocalSyncedState({
      dbKey: 'CUSTOM_MILO__NEWLY_UNLOCKED_ITEM_COUNT',
      fallbackValue: 0,
    });
  const [highestWeeklyTotal, setHighestWeeklyTotal] = useLocalSyncedState({
    dbKey: HIGHEST_WEEKLY_POINT_TOTAL_KEY,
    fallbackValue: 0,
  });

  const tippyRef = useRef<typeof Tippy | null>(null);
  const { trackStructEvent } = useSnowplow();

  const optionsUnlockableWithPoints = useMemo<
    Record<
      AnimationUnlockableWithPoints,
      {
        content: ReactNode;
        ariaLabel?: string | undefined;
        points: number;
      }
    >
  >(
    () => ({
      ENTRY: {
        content: null,
        ariaLabel: 'None',
        points: 0,
      },
      BASKETBALL: {
        content: <BasketballSvg />,
        ariaLabel: 'Basketball',
        points: 900,
      },
      FOOTBALL: {
        content: <FootballSvg />,
        ariaLabel: 'Football',
        points: 2100,
      },
      SANDCASTLE: {
        content: <SandcastleSvg />,
        ariaLabel: 'Sandcastle',
        points: 3300,
      },
      OCTOPUS: {
        content: <OctopusSvg />,
        ariaLabel: 'Octopus',
        points: 4500,
      },
      ELEPHANT: {
        content: <ElephantSvg />,
        ariaLabel: 'Elephant',
        points: 6000,
      },
      FIRECRACKER: {
        content: <FirecrackerSvg />,
        ariaLabel: 'Firecracker',
        points: 8250,
      },
      ROBOT: {
        content: <RobotSvg />,
        ariaLabel: 'Robot',
        points: 10_500,
      },
      CACTUS: {
        content: <CactusSvg />,
        ariaLabel: 'Cactus',
        points: 12_750,
      },
    }),
    [],
  );

  const playIdleAnim = useCallback(
    (animation: Animation) => {
      rive?.fire(animation === 'ENTRY' ? 'ENTRY' : `${animation}_IDLE`);
      setAnimation(animation);
    },
    [rive],
  );

  const selectPickerItem = useCallback(
    (animation: Animation) => {
      rive?.fire(animation === 'ENTRY' ? 'ENTRY' : `${animation}_ACTIVE`);
      setActivePickerItem(animation);
      // @ts-expect-error TS types are wrong
      tippyRef.current?._tippy.hide();
      trackStructEvent({
        category: 'gamification',
        action: 'equipped_asset',
        label: animation,
      });
    },
    [rive, setActivePickerItem, trackStructEvent],
  );

  useEffect(() => {
    playIdleAnim(activePickerItem);
  }, [activePickerItem, playIdleAnim]);

  useEffect(() => {
    if (
      currPoints > highestWeeklyTotal ||
      Object.values(customMiloMonthlyExpeditionItemsState)
        .map(s => s.get)
        .some(v => v === 'unlocked-unseen')
    ) {
      const optionsUnlockableWithPointsValues = Object.values(
        optionsUnlockableWithPoints,
      );
      const prevUnlockedItemsWithPoints =
        optionsUnlockableWithPointsValues.filter(
          o => o.points <= highestWeeklyTotal,
        );
      const nextUnlockedItemsWithPoints =
        optionsUnlockableWithPointsValues.filter(o => o.points <= currPoints);

      const unlockedUnseenItemsWithChallenges = Object.values(
        customMiloMonthlyExpeditionItemsState,
      ).filter(s => s.get === 'unlocked-unseen');
      const newlyUnlockedItemWithPointsCount =
        nextUnlockedItemsWithPoints.length - prevUnlockedItemsWithPoints.length;

      const nextNewlyUnlockedItemCount =
        newlyUnlockedItemWithPointsCount +
        unlockedUnseenItemsWithChallenges.length;

      setNewlyUnlockedItemCount(nextNewlyUnlockedItemCount);
      setHighestWeeklyTotal(currPoints);

      if (newlyUnlockedItemWithPointsCount > 0) {
        unsafeKeys(optionsUnlockableWithPoints)
          .slice(0, newlyUnlockedItemWithPointsCount)
          .forEach(anim => {
            trackStructEvent({
              category: 'gamification',
              action: 'unlocked_custom_asset',
              label: anim,
            });
          });
      }
    }
  }, [
    currPoints,
    highestWeeklyTotal,
    optionsUnlockableWithPoints,
    setHighestWeeklyTotal,
    setNewlyUnlockedItemCount,
    trackStructEvent,
    customMiloMonthlyExpeditionItemsState,
  ]);

  // Show the new item popover when a new item is unlocked
  useEffect(() => {
    if (newlyUnlockedItemCount) {
      // @ts-expect-error TS types are wrong
      tippyRef.current?._tippy.show();
    }
  }, [newlyUnlockedItemCount]);
  const optionsUnlockableWithChallenges = useMemo<
    Record<
      AnimationUnlockableWithChallenges,
      {
        content: ReactNode;
        ariaLabel?: string | undefined;
        challengeMonth: MonthlyChallengeAvailableMonth;
      }
    >
  >(
    () => ({
      FOX: {
        content: <FoxSvg />,
        ariaLabel: 'Fox',
        challengeMonth: '11-2024',
      },
      CAT: {
        content: <EgyptianCatSvg style={{ width: 20, height: 20 }} />,
        ariaLabel: 'Cat',
        challengeMonth: '12-2024',
      },
      PANDA: {
        content: <PandaSvg style={{ width: 20, height: 20 }} />,
        ariaLabel: 'Panda',
        challengeMonth: '01-2025',
      },
    }),
    [],
  );

  const hasNewItems = newlyUnlockedItemCount > 0;
  return (
    // Flex forces any vertical alignment on the Milo image to be ignored
    <div style={{ display: 'flex', position: 'relative' }}>
      <Milo dynamic />
      <div
        // Position the element next to Milo on the bottom left
        style={{
          bottom: 0,
          height: 100,
          position: 'absolute',
          right: '100%',
          width: 100,
        }}
      >
        <div
          // The extra div allows us to work with percentages when positioning
          // the animations relative to Milo.
          style={{
            height: '100%',
            marginLeft: '44%',
            marginTop: '8%',
            position: 'relative',
            width: '100%',
          }}
        >
          <CustomMiloAnims />
          <Tippy
            // @ts-expect-error TS types are wrong
            ref={tippyRef}
            arrow={hasNewItems}
            className={cx(
              styles.tippyReset,
              hasNewItems && styles.tippyArrowEggplant,
            )}
            content={
              hasNewItems ? (
                <NewItemsPopover>
                  <LightningBoltSvg
                    style={{ flexShrink: 0, height: 12, width: 12 }}
                  />{' '}
                  {newlyUnlockedItemCount === 1 ? (
                    <>You’ve unlocked a new item!</>
                  ) : (
                    <>You’ve unlocked {newlyUnlockedItemCount} new items!</>
                  )}
                </NewItemsPopover>
              ) : (
                <Picker
                  onMouseEnter={() => {
                    trackStructEvent({
                      category: 'gamification',
                      action: 'hovered_assets_popover',
                    });
                  }}
                >
                  <PickerItemsGrid>
                    {unsafeKeys(optionsUnlockableWithPoints).map(k => {
                      const [key, { content, ariaLabel, points }] = [
                        k,
                        optionsUnlockableWithPoints[k],
                      ] as const;
                      const isDisabled = points > highestWeeklyTotal;

                      return (
                        <PickerItem
                          key={key}
                          aria-label={ariaLabel}
                          aria-selected={activePickerItem === key}
                          disabled={isDisabled}
                          onClick={() => {
                            selectPickerItem(key);
                          }}
                          onMouseEnter={() => {
                            playIdleAnim(key);
                            if (currPoints >= points && isDisabled) {
                              Logger.error(
                                'Student has earned more points than what is in local storage',
                                {
                                  extra: {
                                    animation: key,
                                    currPoints,
                                    highestWeeklyTotalFromLocalStorage:
                                      localStorage.getItem(
                                        HIGHEST_WEEKLY_POINT_TOTAL_KEY,
                                      ),
                                    highestWeeklyTotalFromState:
                                      highestWeeklyTotal,
                                    points,
                                    studentId: id,
                                  },
                                },
                              );
                            }
                          }}
                          onMouseLeave={() => {
                            playIdleAnim(activePickerItem);
                          }}
                        >
                          {content}
                          {isDisabled && (
                            <Tippy
                              arrow={false}
                              className={styles.tippyReset}
                              content={
                                <PickerTooltip>
                                  <b
                                    style={{ color: 'white', fontWeight: 600 }}
                                  >
                                    {points - currPoints}
                                  </b>{' '}
                                  <LightningBoltSvg
                                    style={{ height: 10, width: 10 }}
                                  />{' '}
                                  left to unlock
                                </PickerTooltip>
                              }
                              duration={[300, 0]}
                              placement="bottom"
                            >
                              <PickerItemOverlay>
                                <LockSvg />
                              </PickerItemOverlay>
                            </Tippy>
                          )}
                        </PickerItem>
                      );
                    })}
                  </PickerItemsGrid>

                  {gamificationEnableMonthlyExpedition && (
                    <div style={{ paddingTop: 6 }}>
                      <HeadingXXS style={{ marginBottom: 4 }}>
                        Class Expedition
                      </HeadingXXS>
                      <PickerItemsGrid>
                        {unsafeKeys(optionsUnlockableWithChallenges).map(k => {
                          const [key, { content, ariaLabel, challengeMonth }] =
                            [k, optionsUnlockableWithChallenges[k]] as const;
                          const isDisabled =
                            customMiloMonthlyExpeditionItemsState[
                              challengeMonth
                            ].get === 'locked';

                          return (
                            <PickerItem
                              key={key}
                              aria-label={ariaLabel}
                              aria-selected={activePickerItem === key}
                              disabled={isDisabled}
                              onClick={() => {
                                selectPickerItem(key);
                              }}
                              onMouseEnter={() => {
                                playIdleAnim(key);
                              }}
                              onMouseLeave={() => {
                                playIdleAnim(activePickerItem);
                              }}
                            >
                              {content}
                              {isDisabled && (
                                <Tippy
                                  arrow={false}
                                  className={styles.tippyReset}
                                  content={
                                    <PickerTooltip>
                                      Complete the{' '}
                                      <b
                                        style={{
                                          color: 'white',
                                          fontWeight: 600,
                                        }}
                                      >
                                        class expedition
                                      </b>{' '}
                                      to unlock
                                    </PickerTooltip>
                                  }
                                  duration={[300, 0]}
                                  placement="bottom"
                                >
                                  <PickerItemOverlay>
                                    <LockSvg />
                                  </PickerItemOverlay>
                                </Tippy>
                              )}
                            </PickerItem>
                          );
                        })}
                      </PickerItemsGrid>
                    </div>
                  )}
                </Picker>
              )
            }
            interactive
            placement="left"
          >
            <div
              // Using an extra container prevents Tippy from being positioned
              // relative to a transformed click area.
              style={{
                cursor: 'pointer',
                height: 48,
                inset: 0,
                margin: 'auto auto 14.5%',
                opacity: animation === 'ENTRY' ? 1 : 0,
                position: 'absolute',
                width: 48,
              }}
              onClick={() => {
                if (animation === 'ENTRY') return;
                rive?.fire(`${animation}_ACTIVE`);
              }}
              onMouseEnter={() => {
                setNewlyUnlockedItemCount(0);

                unsafeKeys(customMiloMonthlyExpeditionItemsState).forEach(
                  challengeMonth => {
                    const item =
                      customMiloMonthlyExpeditionItemsState[challengeMonth];
                    if (item.get === 'unlocked-unseen') {
                      item.set('unlocked-seen');
                    }
                  },
                );
              }}
            >
              <ClickAreaSvg className={styles.clickArea} />
            </div>
          </Tippy>
        </div>
      </div>
    </div>
  );
}

const ClickAreaSvg = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  function ClickAreaSvg(props, ref) {
    return (
      <svg
        ref={ref}
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle
          cx="24"
          cy="24"
          r="22"
          fill="white"
          fillOpacity="0.32"
          stroke="currentColor"
          strokeWidth="4"
          strokeDasharray="5 5"
        />
      </svg>
    );
  },
);

const Picker = styled.div({
  backgroundColor: 'white',
  borderRadius: 8,
  boxShadow: '0 8.75px 49px #3e3e4c26',
  padding: '6px 8px',
});

const PickerItemsGrid = styled.div({
  display: 'grid',
  gap: '6px 8px',
  gridTemplateColumns: 'repeat(4, 1fr)',
});

const PickerItem = styled.button({
  alignItems: 'center',
  backgroundColor: 'white',
  border: `2px solid ${colors.porcelain}`,
  borderRadius: 6,
  cursor: 'pointer',
  display: 'flex',
  height: 28,
  justifyContent: 'center',
  overflow: 'hidden',
  padding: 2,
  position: 'relative',
  transition: 'border-color .2s, transform .2s',
  width: 36,

  ':hover:not(:disabled)': {
    borderColor: colors.eggplant,
    transform: 'scale(1.1)',
  },

  ':disabled': {
    cursor: 'not-allowed',
  },

  ':empty': {
    backgroundImage: `url('data:image/svg+xml,<svg viewBox="0 0 32 24" xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="100%" x2="100%" y2="0" stroke="${encodeURIComponent(
      colors.porcelain,
    )}" stroke-width="2" /></svg>')`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },

  '&[aria-selected="true"]': {
    borderColor: colors.eggplant,
  },
});

const PickerItemOverlay = styled.div({
  alignItems: 'center',
  backgroundColor: '#d4d7d9bd',
  display: 'flex',
  inset: 0,
  justifyContent: 'center',
  position: 'absolute',
});

const PickerTooltip = styled.div({
  alignItems: 'center',
  backgroundColor: colors.grey,
  borderRadius: 6,
  color: colors.grey10,
  display: 'flex',
  fontFamily: fontFamily.body,
  fontSize: 10,
  gap: 2,
  lineHeight: 1.2,
  padding: '8px',
});

const NewItemsPopover = styled(PickerTooltip)({
  backgroundColor: colors.eggplant,
  color: 'white',
  fontSize: 12,
  fontWeight: 600,
  gap: 4,
  padding: 8,
});

const spin = keyframes({
  '0': {
    transform: 'rotate(0)',
  },

  '100%': {
    transform: 'rotate(360deg)',
  },
});

const pulse = keyframes({
  '0': {
    transform: 'scale(1)',
  },

  '50%': {
    transform: 'scale(1.1)',
  },

  '100%': {
    transform: 'scale(1)',
  },
});

const styles = {
  clickArea: css({
    animationComposition: 'accumulate',
    animationDuration: '10s, 2s',
    animationIterationCount: 'infinite',
    // The order of the animations matters here. I'm not sure why.
    animationName: `${spin}, ${pulse}`,
    animationTimingFunction: 'linear, ease-in-out',
    color: colors.eggplant,
    inset: 0,
    position: 'absolute',
    transition: 'scale .2s',

    ':hover': {
      animationPlayState: 'paused',
      // Allows for an accumulative effect on top of the transforms set by the
      // animations. It's not as well supported as transform, but it's a hover
      // effect, so who cares right?
      scale: '1.33',
    },
  }),
  // Reset Tippy's default styles so that we can handle them ourselves via the
  // components passed to the `content` prop.
  tippyReset: css({
    '&.tippy-box': {
      backgroundColor: 'transparent',
    },

    '.tippy-content': {
      padding: 0,
    },
  }),
  tippyArrowEggplant: css({
    '.tippy-arrow': {
      color: colors.eggplant,
    },
  }),
} as const;
