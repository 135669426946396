/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type featureFlags = {
    readonly canAccessNewDistrictReport: boolean;
    readonly canAccessNewDistrictReportImpact: boolean;
    readonly canAccessNewDistrictReportSchoolLevel: boolean;
    readonly canAccessNewDistrictReportTemplates: boolean;
    readonly canvasEnableGradePassback: boolean;
    readonly enableAiSuggestQuestions: boolean;
    readonly hasEnabledAccessibilityMode: boolean;
    readonly showWorkbookOnboarding: boolean;
    readonly adminReports: boolean;
    readonly advancedReports: boolean;
    readonly allowMultipleTeachersInClass: boolean;
    readonly assignTasksToIndividuals: boolean;
    readonly assignableTasks: boolean;
    readonly bulkAssignTasks: boolean;
    readonly classicUi: boolean;
    readonly demoCheckin: boolean;
    readonly diagnostics: boolean;
    readonly engageLite: boolean;
    readonly engageTasks: boolean;
    readonly primaryTeacherNotes: boolean;
    readonly printableWorksheets: boolean;
    readonly schoolReports: boolean;
    readonly teacherReporting: boolean;
    readonly templates: boolean;
    readonly textbook: boolean;
    readonly textbookOverviews: boolean;
    readonly topicAssessment: boolean;
    readonly unlimitedClasses: boolean;
    readonly unlimitedOpenPrompt: boolean;
    readonly worksheetAnswerKeys: boolean;
    readonly worksheets: boolean;
    readonly canEditLanternQuestions: boolean;
};
export type featureFlags$data = featureFlags;
export type featureFlags$key = {
    readonly " $data"?: featureFlags$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"featureFlags">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "featureFlags",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessNewDistrictReport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessNewDistrictReportImpact",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessNewDistrictReportSchoolLevel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessNewDistrictReportTemplates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canvasEnableGradePassback",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiSuggestQuestions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasEnabledAccessibilityMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showWorkbookOnboarding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminReports",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "advancedReports",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowMultipleTeachersInClass",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assignTasksToIndividuals",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assignableTasks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bulkAssignTasks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "classicUi",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "demoCheckin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diagnostics",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "engageLite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "engageTasks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryTeacherNotes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "printableWorksheets",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "schoolReports",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "teacherReporting",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textbook",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textbookOverviews",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topicAssessment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unlimitedClasses",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unlimitedOpenPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worksheetAnswerKeys",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worksheets",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEditLanternQuestions",
      "storageKey": null
    }
  ],
  "type": "TypedFeatureFlag",
  "abstractKey": null
};
(node as any).hash = 'adb7e33c7e455af25f249d189ef87d4a';
export default node;
