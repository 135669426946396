/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DevGetProblemEventsBackgroundRequestsPerMinuteLimit = "N100" | "N1000" | "N10000" | "N200" | "N2000" | "N300" | "N3000" | "N400" | "N4000" | "N500" | "N5000" | "N600" | "N6000" | "N700" | "N7000" | "N800" | "N8000" | "N900" | "N9000";
export type GamificationForceActiveDailyChallenge = "COMPLETE_TASK" | "NONE";
export type featureFlagsV2 = {
    readonly canPreviewProblemEventDataInSkills: boolean;
    readonly catfaEnableSecondarySaveAsTemplate: boolean;
    readonly checkinsEnableDiscoveryCheckinCapGrades: boolean;
    readonly checkinsEnableGrowthPeriodFilter: boolean;
    readonly devGetProblemEventsBackgroundRequestsPerMinuteLimit: DevGetProblemEventsBackgroundRequestsPerMinuteLimit;
    readonly devSkillsReportEnableTimeFilter: boolean;
    readonly enableAiOpenPrompt: boolean;
    readonly enableAiOpenPromptDiscountedPrice: boolean;
    readonly enableAiPremiumTrial: boolean;
    readonly exportMasteryReportAsBackgroundTask: boolean;
    readonly gamificationEnableChallengesDaily: boolean;
    readonly gamificationEnableCustomisation: boolean;
    readonly gamificationEnableInlineLeaderboardNotification: boolean;
    readonly gamificationEnableLeaderboardClassSelectionPrompt: boolean;
    readonly gamificationEnableLeaderboardDock: boolean;
    readonly gamificationEnableLeaderboardModalV2: boolean;
    readonly gamificationEnableLeaderboardNotification: boolean;
    readonly gamificationEnableLeaderboardTab: boolean;
    readonly gamificationEnableLiveModePromote: boolean;
    readonly gamificationEnableMonthlyExpedition: boolean;
    readonly gamificationEnablePointsCheckins: boolean;
    readonly gamificationEnableQuestionSuccessBannerV2: boolean;
    readonly gamificationEnableStudentDashboardV2: boolean;
    readonly gamificationEnableTeacherStickers: boolean;
    readonly gamificationEnableTugOfWar: boolean;
    readonly gamificationForceActiveDailyChallenge: GamificationForceActiveDailyChallenge;
    readonly gamificationHighlightStreaksInWorkbookHeader: boolean;
    readonly globalSearchEnableMVP: boolean;
    readonly graphqlEnableNodeResolver: boolean;
    readonly hasPreviewProblemEventDataInSkills: boolean;
    readonly hasStudentAppSpa: boolean;
    readonly perfUpdateIndirectMasteryAsync: boolean;
    readonly preTopicTestCheckInCanAccess: boolean;
    readonly printEnablePageBreaksAfterPTs: boolean;
    readonly prototypeEnableWorksheetChat: boolean;
    readonly reportsEnableRecommendTasks: boolean;
    readonly showMaintenanceBanner: boolean;
    readonly skillsActivityReportEnableReport: boolean;
    readonly skillsAlchemyGradeLevelA13: boolean;
    readonly skillsAlchemyUseSubtopicGradeFilter: boolean;
    readonly skillsDisablePENegativePropagation: boolean;
    readonly skillsEnableCurriculumClassAverage: boolean;
    readonly skillsReportsEnableStudentSkillsUpdate: boolean;
    readonly skillsShowGradeLevelOverride: boolean;
    readonly supportEnableHubspotBubble: boolean;
    readonly templatesEnableSortLastAssigned: boolean;
    readonly topicTestsEnableMVP: boolean;
    readonly workbookEnableDesmosCalculators: boolean;
    readonly workbookEnableIncorrectStepPrompt: boolean;
    readonly workbookEnableSpanishSupport: boolean;
    readonly workbookEnableTTSSupport: boolean;
};
export type featureFlagsV2$data = featureFlagsV2;
export type featureFlagsV2$key = {
    readonly " $data"?: featureFlagsV2$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"featureFlagsV2">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "featureFlagsV2",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canPreviewProblemEventDataInSkills",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "catfaEnableSecondarySaveAsTemplate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkinsEnableDiscoveryCheckinCapGrades",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkinsEnableGrowthPeriodFilter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "devGetProblemEventsBackgroundRequestsPerMinuteLimit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "devSkillsReportEnableTimeFilter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiOpenPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiOpenPromptDiscountedPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiPremiumTrial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exportMasteryReportAsBackgroundTask",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableChallengesDaily",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableCustomisation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableInlineLeaderboardNotification",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLeaderboardClassSelectionPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLeaderboardDock",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLeaderboardModalV2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLeaderboardNotification",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLeaderboardTab",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLiveModePromote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableMonthlyExpedition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnablePointsCheckins",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableQuestionSuccessBannerV2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableStudentDashboardV2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableTeacherStickers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableTugOfWar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationForceActiveDailyChallenge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationHighlightStreaksInWorkbookHeader",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "globalSearchEnableMVP",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "graphqlEnableNodeResolver",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviewProblemEventDataInSkills",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasStudentAppSpa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "perfUpdateIndirectMasteryAsync",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preTopicTestCheckInCanAccess",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "printEnablePageBreaksAfterPTs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prototypeEnableWorksheetChat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reportsEnableRecommendTasks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showMaintenanceBanner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skillsActivityReportEnableReport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skillsAlchemyGradeLevelA13",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skillsAlchemyUseSubtopicGradeFilter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skillsDisablePENegativePropagation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skillsEnableCurriculumClassAverage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skillsReportsEnableStudentSkillsUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skillsShowGradeLevelOverride",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supportEnableHubspotBubble",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templatesEnableSortLastAssigned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topicTestsEnableMVP",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookEnableDesmosCalculators",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookEnableIncorrectStepPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookEnableSpanishSupport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookEnableTTSSupport",
      "storageKey": null
    }
  ],
  "type": "FeatureFlagsV2",
  "abstractKey": null
};
(node as any).hash = '194b7df6f81cce7dae664c9a6c2eee58';
export default node;
