import WaypointsIcon from 'ms-components/icons/Waypoints';
import useFeatureFlagsV2 from 'ms-helpers/useFeatureFlagsV2';
import { HSpacer } from 'ms-pages/Lantern/primitives/Stack';
import Tooltip from 'ms-pages/Lantern/primitives/Tooltip';
import type { ButtonSize } from 'ms-ui-primitives/Button';
import Button from 'ms-ui-primitives/Button';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import LinkButton from 'ms-ui-primitives/Button/ReactRouterLinkButton';

type Props = {
  onClick?: (() => void) | undefined;
  label: string;
  isDisabled?: boolean | undefined;
  secondary?: boolean | undefined;
  tertiary?: boolean | undefined;
  padding?: number | undefined;
  height?: number | undefined;
  size: ButtonSize;
  tooltipContent?: string | undefined;
  checkInUrl?: string | undefined;
  trackingId?: string | undefined;
  showIcon?: boolean | undefined;
};

export default function StartCheckInButton({
  onClick,
  label,
  isDisabled = false,
  secondary = false,
  tertiary = false,
  padding,
  height,
  size,
  tooltipContent,
  trackingId,
  showIcon = true,
  ...props
}: Props) {
  const { hasStudentAppSpa } = useFeatureFlagsV2();

  const commonProps = {
    onClick,
    type: tertiary ? 'tertiary' : secondary ? 'secondary' : 'primary',
    size,
    isDisabled,
    label,
    trackingId,
    padding,
    height,
    children: (
      <>
        {tertiary === false && showIcon !== false && (
          <>
            <WaypointsIcon />
            <HSpacer width={12} />
          </>
        )}
        {label}
      </>
    ),
  } as const;

  const buttonComponent = props.checkInUrl ? (
    hasStudentAppSpa ? (
      <LinkButton {...commonProps} to={props.checkInUrl} />
    ) : (
      <AnchorButton {...commonProps} href={props.checkInUrl} />
    )
  ) : (
    <Button {...commonProps} />
  );

  return tooltipContent != null ? (
    <Tooltip content={tooltipContent}>
      <div>{buttonComponent}</div>
    </Tooltip>
  ) : (
    buttonComponent
  );
}
